import type { SearchOptions, SearchResponse } from '@algolia/client-search';
import { ValidatorServiceInterface } from '@services/validator/validator';
import { Provider } from '~/contexts/Provider';

import { _AlgoliaClient } from '../client';

const SUFFIX_ALGOLIA_INDEX =
  process.env.NEXT_PUBLIC_SUFFIX_ALGOLIA_INDEX || 'dev';

enum OrderByOptionsFromMagnolia {
  TEXT_RELEVANCE = 'provider_poc_dev',
  REVIEWS_DESC = 'provider_poc_dev_average_rating_desc',
  REVIEWS_ASC = 'provider_poc_dev_average_rating_asc',
  DISTANCE_FROM_SEARCH = 'provider_poc_dev_geo',
}

export abstract class AlgoliaRepository {
  constructor(
    protected readonly algoliaClient: _AlgoliaClient,
    protected readonly validatorService: ValidatorServiceInterface
  ) {}

  concatFilter(newFilter: string, filters?: string) {
    return filters ? `${filters} AND ${newFilter}` : newFilter;
  }

  parseSearchOption(
    searchOption: SearchOptions,
    keyword?: string,
    domainTag?: string
  ) {
    let finalKeyword = keyword || '';
    let filters = this.concatFilter(
      `_tags:${domainTag} AND NOT _tags:'Low Income Affordable'`,
      searchOption.filters
    );

    if (this.validatorService.isZipCode(finalKeyword)) {
      filters = this.concatFilter(
        `address.zipCode: '${finalKeyword}'`,
        filters
      );
      finalKeyword = '';
    }

    const stateAbbreviation =
      this.validatorService.getStateAbbreviation(finalKeyword);

    if (stateAbbreviation) {
      const state =
        this.validatorService.parseStateAbbreviation(stateAbbreviation);
      finalKeyword = finalKeyword.replace(stateAbbreviation, state);
    }

    return {
      searchKeyword: finalKeyword,
      filters: filters,
    };
  }

  getProviderSearchIndex(orderBy?: string) {
    const orderByOptions = {
      [OrderByOptionsFromMagnolia.TEXT_RELEVANCE]: `provider_${SUFFIX_ALGOLIA_INDEX}`,
      [OrderByOptionsFromMagnolia.REVIEWS_DESC]: `provider_${SUFFIX_ALGOLIA_INDEX}_average_rating_desc`,
      [OrderByOptionsFromMagnolia.REVIEWS_ASC]: `provider_${SUFFIX_ALGOLIA_INDEX}_average_rating_asc`,
      [OrderByOptionsFromMagnolia.DISTANCE_FROM_SEARCH]: `provider_${SUFFIX_ALGOLIA_INDEX}_geo`,
    };

    const DEFAULT_SEARCH_INDEX =
      orderByOptions[OrderByOptionsFromMagnolia.TEXT_RELEVANCE];

    return orderBy &&
      orderBy !== OrderByOptionsFromMagnolia.DISTANCE_FROM_SEARCH
      ? orderByOptions[orderBy]
      : DEFAULT_SEARCH_INDEX;
  }

  async getProviders(
    searchOption: SearchOptions,
    keyword?: string,
    orderBy?: string
  ): Promise<SearchResponse<Provider>> {
    const searchProvidersIndex = this.getProviderSearchIndex(orderBy);

    const algoliaResponse = await this.algoliaClient.search<Provider>(
      searchProvidersIndex,
      keyword ?? '',
      {
        ...searchOption,
      }
    );

    return algoliaResponse;
  }

  async getProvidersEnhanced(
    searchOption: SearchOptions,
    keyword?: string
  ): Promise<SearchResponse<Provider>> {
    const algoliaResponse = await this.algoliaClient.search<Provider>(
      `provider_${SUFFIX_ALGOLIA_INDEX}_enhanced_v2`,
      keyword ?? '',
      {
        ...searchOption,
      }
    );

    return algoliaResponse;
  }

  async getMultipleQueries(
    queries: {
      indexName: string;
      query?: string;
      params?: Record<string, unknown>;
    }[]
  ): Promise<SearchResponse<Provider>[]> {
    const algoliaResponse = await this.algoliaClient.multipleQueries(queries);
    return algoliaResponse;
  }
}
