import startCase from 'lodash/startCase';
import { CareTypeObject } from './EnhancedSearch/ServerComponent';

const KNOW_CARE_TYPES = {
  'continuing-care-retirement-communities': 'continuing-care',
  'continuing-care-communities': 'continuing-care',
};

export const getCareTypeLabel = (careType: string) => {
  return careType === 'continuing-care' || careType === 'Continuing Care'
    ? 'Continuing Care Communities'
    : startCase(careType);
};

export const groupItemsByType = (items: CareTypeObject[]) => {
  return items?.reduce((acc, item) => {
    if (!acc[item.rollUpType]) {
      acc[item.rollUpType] = [];
    }
    acc[item.rollUpType].push(item);
    return acc;
  }, {});
};

export const mappedCareTypes = (
  careType: string,
  careTypes: CareTypeObject[]
) => {
  const knownIssues = KNOW_CARE_TYPES[careType] ?? careType;
  const careTypesNames = careTypes?.map((items) => items.id);
  const result = careTypesNames?.find(
    (el) => el.includes(knownIssues) || knownIssues.includes(el)
  );
  return result;
};
