import type { SearchOptions, SearchResponse } from '@algolia/client-search';
import { validatorService } from '@services/validator/validator';
import AlgoliaClient from '../client';
import { AlgoliaRepository } from './algolia.repository';
import { Provider } from '~/contexts/Provider';

export class AlgoliaSeniorHomesRepository extends AlgoliaRepository {
  constructor() {
    super(AlgoliaClient, validatorService);
  }

  async getProviders(
    searchOption: SearchOptions,
    keyword?: string,
    orderBy?: string
  ): Promise<SearchResponse<Provider>> {
    const { searchKeyword, filters } = this.parseSearchOption(
      searchOption,
      keyword,
      'SENIOR_HOMES'
    );
    const providers = super.getProviders(
      { ...searchOption, filters },
      searchKeyword,
      orderBy
    );
    return providers;
  }

  async getProvidersEnhanced(
    searchOption: SearchOptions,
    keyword?: string
  ): Promise<SearchResponse<Provider>> {
    const { searchKeyword, filters } = this.parseSearchOption(
      searchOption,
      keyword,
      'SENIOR_HOMES'
    );
    const providers = await super.getProvidersEnhanced(
      { ...searchOption, filters },
      searchKeyword
    );

    return providers;
  }

  async getMultipleQueries(
    queries: {
      indexName: string;
      query?: string;
      params?: Record<string, unknown>;
    }[]
  ): Promise<SearchResponse<Provider>[]> {
    queries.forEach((query, index) => {
      if (query.params) {
        const { searchKeyword, filters } = this.parseSearchOption(
          query.params,
          query.query,
          'SENIOR_HOMES'
        );
        (queries[index].params = { ...query.params, filters }), searchKeyword;
      }
    });
    const algoliaResponse = await this.algoliaClient.multipleQueries(queries);
    return algoliaResponse;
  }
}
