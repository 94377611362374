import sanitizeHtml from 'sanitize-html';
import kebabCase from 'lodash/kebabCase';
import { removeTrailingNewline } from '~/utils/strings';
import { buildCareTypesDescription } from '~/utils/providers';
import { LocationResponse } from '@services/modular-monolith/types/location.type';
import { Provider } from '~/contexts/Provider';
import { formatRating } from '@utils/number';

export const locationResponseAdapter = (
  provider: LocationResponse
): Provider => {
  const hasActivePromotions = provider.promotions?.length > 0;
  const hasImages = provider.photos?.length > 0;
  const tags = provider.services?.map((service) => service.category.name) || [];
  const reviews =
    provider.reviews &&
    provider.reviews.map((review) => {
      return {
        ...review,
        providerResponse:
          review.providerResponse &&
          sanitizeHtml(removeTrailingNewline(review.providerResponse)),
      };
    });

  const providerDescription =
    provider.description &&
    sanitizeHtml(provider.description, {
      allowedAttributes: {
        a: [...sanitizeHtml.defaults.allowedAttributes['a'], 'data-*'],
      },
      transformTags: {
        h1: 'p',
        h2: 'p',
        h3: 'p',
        h4: 'p',
        h5: 'p',
        h6: 'p',
      },
    });
  // TODO: Update ModMon or the DXP to handle multiple years
  // 1. Update ModMon to not save historical data
  //
  // 2. Figure out a way to handle multiple years on the DXP side so we don't lose all awards at the start of a new year while HCP data updates.
  // For now, we're just going to filter out the current year's awards but this is not a long term solution.
  const homeCarePulseAwards = provider.awards?.filter((award) =>
    [
      'Provider of Choice',
      'Employer of Choice',
      'Leader in Experience',
      'Home Care Pulse Certified',
    ].includes(award.name)
  );
  const currentHomeCarePulseAwards = homeCarePulseAwards?.filter(
    (award) => award.year === new Date().getFullYear()
  );

  return {
    ...provider,
    averageRating:
      provider.averageRating &&
      parseFloat(formatRating(provider.averageRating)),
    webSiteDetailsVariants: provider.webSiteDetailsVariants?.map((variant) => {
      return {
        ...variant,
        averageRating:
          variant.averageRating && formatRating(variant.averageRating),
      };
    }),
    description: providerDescription,
    caringStars: provider.awards?.filter(
      (award) => award.name === 'Caring Stars'
    ),
    homeCarePulse: currentHomeCarePulseAwards,
    rollup: provider.rollupType,
    hasActivePromotions,
    hasImages,
    tags,
    reviews,
    careType: kebabCase(tags[0] || ''),
    careTypesDescription: buildCareTypesDescription(
      tags,
      provider.city?.urlName,
      provider.state?.urlName
    ),
  };
};
