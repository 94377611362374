export const videoIframeResponseAdapter = async (
  locationDescription: string
): Promise<string> => {
  let description = locationDescription;

  if (locationDescription.includes('iframe')) {
    const iframeSrcRegex =
      /<iframe[^>]*\ssrc=["'](https?:\/\/[^"']+)["'][^>]*>/gi;
    let iterator = locationDescription.matchAll(iframeSrcRegex) ?? [];
    const iframeSources = [...iterator];

    for (const iframe of iframeSources) {
      let iframeSrc = iframe[1];
      if (
        iframeSrc &&
        (iframeSrc.includes('youtube') || iframeSrc.includes('vimeo'))
      ) {
        if (iframeSrc.includes('http://')) {
          const newIframeSrc = iframeSrc.replace('http://', 'https://');
          description = description.replace(iframeSrc, newIframeSrc);
          iframeSrc = newIframeSrc;
        }
        const response = await fetch(iframeSrc);
        if (response.status !== 200) {
          const srcToFind = iframeSrc.replace('?', '\\?');
          const regexPattern = `<iframe[^>]*src=["']${srcToFind}["'][^>]*>[^<]*<\\/iframe>`;

          const iframeRegex = new RegExp(regexPattern, 'ig');

          const iframeDeclaration = description.match(iframeRegex)?.[0];

          if (iframeDeclaration) {
            description = description.replace(iframeDeclaration, '');
          }
        }
      }
    }
  }

  return description;
};
