import unescape from 'lodash/unescape';
import sanitizeHtml from 'sanitize-html';
import { tenantSwitcher } from '~/contexts/TenantFunctionsContext';
import { ProviderReview, ReviewRawData } from '~/types/reviews';

export const reviewResponseAdapter = ({
  data,
  city,
  state,
  domain,
}: {
  data: ReviewRawData[];
  city?: string;
  state?: string;
  domain: string;
}): ProviderReview[] => {
  const reviews = data.map(({ review, location }) => {
    const {
      id,
      careType,
      content,
      createdAt,
      name,
      providerResponse,
      rating,
      title,
    } = review;
    const {
      name: locationName,
      phoneNumber,
      image,
      slug,
      cityURLName,
      stateURLName,
      rollupType,
      url,
    } = location;
    const cityPath = city || cityURLName;
    const statePath = state || stateURLName;
    const path = tenantSwitcher(domain).getReviewPath({
      rollupType,
      state: statePath,
      city: cityPath,
      slug,
      url,
    });
    const resourceUrl = `${domain}${path}`;

    return {
      id,
      authorName: name,
      careType,
      content: unescape(sanitizeHtml(content)),
      createdAt,
      image,
      path,
      providerResponse: providerResponse,
      rating,
      resourceName: locationName,
      resourceUrl,
      slug,
      telephone: phoneNumber,
      title,
    };
  });
  return reviews;
};
