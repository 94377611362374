import { getStateNameFromAbbreviation } from '@utils/UsStates';

const REGEX_CONST = {
  zipCode: /(^\d{5}$)|(^\d{5}-?\d{4}$)/,
  stateAbbreviation: /, ?([a-zA-Z]{2})/,
};

export interface ValidatorServiceInterface {
  isZipCode: (value: string) => boolean;
  getOnlyNumbers: (value: string) => string;
  getStateAbbreviation: (value: string) => string;
  parseStateAbbreviation: (value: string) => string;
}

export class ValidatorService implements ValidatorServiceInterface {
  constructor() {}

  isZipCode(value: string) {
    const onlyNumbers = this.getOnlyNumbers(value || '');
    const matches = onlyNumbers.match(new RegExp(REGEX_CONST.zipCode));

    if (matches === null) return false;
    return true;
  }

  getOnlyNumbers(text: string) {
    return text.replace(/\D/g, '');
  }

  getStateAbbreviation(str: string) {
    const match = new RegExp(REGEX_CONST.stateAbbreviation).exec(str);
    return match?.[1] ?? '';
  }

  parseStateAbbreviation(str: string) {
    return getStateNameFromAbbreviation(str) || str;
  }
}

export const validatorService = new ValidatorService();
