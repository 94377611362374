export enum Source {
  ALGOLIA = 'ALGOLIA',
  API = 'API',
  CACHE = 'CACHE',
  LEGACY = 'LEGACY',
}

export interface JsonApiResponse {
  data: object | object[] | null;
  meta?: {
    source?: Source;
  };
}
