import type { SearchOptions, SearchResponse } from '@algolia/client-search';
import { checkIfIsASeniorHomesDomain } from '@utils/isSeniorHomesDomain';
import { Domain } from '~/types/Domains';
import { AlgoliaCaringRepository } from './repositories/algolia.caring.repository';
import { AlgoliaRepository } from './repositories/algolia.repository';
import { AlgoliaSeniorHomesRepository } from './repositories/algolia.seniorhomes.repository';
import { Provider } from '~/contexts/Provider';

export class AlgoliaService {
  private algoliaRepository: AlgoliaRepository;

  constructor(domain?: Domain) {
    if (!domain) {
      if (typeof window !== undefined) {
        domain = window.location.hostname as Domain;
      } else {
        throw new Error(
          '🚨 Algolia service requires a domain when running on server side.'
        );
      }
    }

    const isASeniorHomesDomain = checkIfIsASeniorHomesDomain(domain);

    if (isASeniorHomesDomain) {
      this.algoliaRepository = new AlgoliaSeniorHomesRepository();
    } else {
      this.algoliaRepository = new AlgoliaCaringRepository();
    }
  }

  async getProviders(
    searchOption: SearchOptions,
    keyword?: string,
    orderBy?: string
  ) {
    return this.algoliaRepository.getProviders(searchOption, keyword, orderBy);
  }

  async getProvidersEnhanced(
    queries: {
      indexName: string;
      query?: string;
      params?: Record<string, unknown>;
    }[]
  ): Promise<SearchResponse<Provider>[]> {
    return await this.algoliaRepository.getMultipleQueries(queries);
  }
}
